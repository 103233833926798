/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

/**
 * Hide visually and from screen readers.
 */

.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
