/*
  Project: DelMorgan & Co.
  Author: xfive
 */

@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';
@import '~swiper/swiper-bundle.min';
@import '~lity/dist/lity.min';
@import 'settings/_global.scss';
@import 'tools/_breakpoints.scss';
@import 'tools/_clearfix.scss';
@import 'tools/_hidden.scss';
@import 'generic/_box-sizing.scss';
@import 'generic/_font-face.scss';
@import 'generic/_normalize.scss';
@import 'generic/_reset.scss';
@import 'generic/_shared.scss';
@import 'elements/_blockquote.scss';
@import 'elements/_headings.scss';
@import 'elements/_hr.scss';
@import 'elements/_html.scss';
@import 'elements/_images.scss';
@import 'elements/_links.scss';
@import 'elements/_lists.scss';
@import 'elements/_tables.scss';

@import 'objects/_layout.scss';
@import 'objects/_list-bare.scss';
@import 'objects/_list-inline.scss';
@import 'objects/_media.scss';
@import 'objects/_table.scss';
@import 'objects/_wrapper.scss';
@import 'components/_btn.scss';
@import 'components/_comment-form.scss';
@import 'components/_comment.scss';
@import 'components/_footer.scss';
@import 'components/_header.scss';
@import 'components/_main-nav.scss';
@import 'components/_post.scss';
@import 'utilities/_align.scss';
@import 'utilities/_clearfix.scss';
@import 'utilities/_hide.scss';
