/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@font-face {
  font-family: 'DM Serif Display';
  src: url('./../assets/fonts/DMSerifDisplay-Italic.eot');
  src: url('./../assets/fonts/DMSerifDisplay-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/DMSerifDisplay-Italic.woff2') format('woff2'),
    url('./../assets/fonts/DMSerifDisplay-Italic.woff') format('woff'),
    url('./../assets/fonts/DMSerifDisplay-Italic.ttf') format('truetype'),
    url('./../assets/fonts/DMSerifDisplay-Italic.svg#DMSerifDisplay-Italic')
      format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./../assets/fonts/DMSans-Bold.eot');
  src: url('./../assets/fonts/DMSans-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/DMSans-Bold.woff2') format('woff2'),
    url('./../assets/fonts/DMSans-Bold.woff') format('woff'),
    url('./../assets/fonts/DMSans-Bold.ttf') format('truetype'),
    url('./../assets/fonts/DMSans-Bold.svg#DMSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Serif Display';
  src: url('./../assets/fonts/DMSerifDisplay-Regular.eot');
  src: url('./../assets/fonts/DMSerifDisplay-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/DMSerifDisplay-Regular.woff2') format('woff2'),
    url('./../assets/fonts/DMSerifDisplay-Regular.woff') format('woff'),
    url('./../assets/fonts/DMSerifDisplay-Regular.ttf') format('truetype'),
    url('./../assets/fonts/DMSerifDisplay-Regular.svg#DMSerifDisplay-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./../assets/fonts/DMSans-Regular.eot');
  src: url('./../assets/fonts/DMSans-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/DMSans-Regular.woff2') format('woff2'),
    url('./../assets/fonts/DMSans-Regular.woff') format('woff'),
    url('./../assets/fonts/DMSans-Regular.ttf') format('truetype'),
    url('./../assets/fonts/DMSans-Regular.svg#DMSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./../assets/fonts/DMSans-Medium.eot');
  src: url('./../assets/fonts/DMSans-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./../assets/fonts/DMSans-Medium.woff2') format('woff2'),
    url('./../assets/fonts/DMSans-Medium.woff') format('woff'),
    url('./../assets/fonts/DMSans-Medium.ttf') format('truetype'),
    url('./../assets/fonts/DMSans-Medium.svg#DMSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
