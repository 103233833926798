/* ==========================================================================
   #LAYOUT
   ========================================================================== */

@layer base {
  /* Global style */

  *,
  ::before,
  ::after {
    @apply box-border;
  }

  html {
    @apply overflow-x-hidden;
  }

  a:hover,
  a {
    @apply no-underline;
  }

  ul {
    @apply list-none m-0 p-0;
  }

  p,
  .p {
    @apply font-fontbase;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    @apply font-heading;
  }

  h5,
  .h5,
  h6,
  .h6 {
    @apply font-fontbase;
  }

  button:focus,
  input:focus,
  img:focus,
  textarea:focus {
    @apply outline-none #{!important};
  }

  .xl-container-fluid {
    @apply smscreen1:px-20 mdscreen:px-40 lgscreen:px-40 xlscreen:px-100 desktop:px-100 largedesktop:px-100 2xl:px-192 extlargedesktop1:max-w-[1536px] extlargedesktop1:mx-auto extlargedesktop1:px-0;
  }

  h1.bannerintitle {
    @apply font-heading font-normal text-30 leading-36 sm:text-40 sm:leading-46 mdscreen2:text-40 mdscreen2:leading-46  md:text-80 md:leading-80 lgscreen:text-50 lgscreen:leading-50 xlscreen:text-50 xlscreen:leading-50 desktop:text-50 desktop:leading-50 lg:text-80 lg:leading-80;
  }

  .newsdetail_headtitle {
    @apply text-30 leading-36 sm:text-40 sm:leading-46 md:text-60 md:leading-64;
  }

  h2.bannertitle {
    @apply font-heading font-normal text-60 leading-64 smscreen:text-74 smscreen:leading-80 md:text-110 md:leading-100 lg:text-80 lg:leading-80 desktop:text-80 desktop:leading-80 2xl:text-120 2xl:leading-120;
  }

  h2 {
    @apply font-normal font-heading text-30 leading-36 sm:text-40 sm:leading-46 md:leading-64 md:text-60 lgscreen:text-40 lgscreen:leading-46 xlscreen:text-40 xlscreen:leading-46 desktop:text-40 desktop:leading-46;
  }

  h3 {
    @apply font-heading font-normal text-30 leading-36 sm:text-40 sm:leading-46 md:text-40 md:leading-46;
  }

  h4 {
    @apply font-heading font-normal text-30 leading-36 md:text-40 md:leading-46;
  }

  h6 {
    @apply font-fontbase font-medium text-12 leading-16 md:text-14 md:leading-18 text-gray capitalize;
  }

  h5 {
    @apply font-fontbase font-normal text-16 leading-20 md:text-30 md:leading-36 xlscreen:text-20 xlscreen:leading-26 desktop:text-20 desktop:leading-26;
  }

  .content.head_video-content p {
    @apply font-fontbase font-medium text-14 leading-18 sm:text-16 sm:leading-20 md:text-30 md:leading-36 lg:text-20 lg:leading-24 desktop:text-24 desktop:leading-26 2xl:text-30 2xl:leading-36;
  }

  /* .head_video-content p {

    @apply sm:text-16 sm:leading-20;

  } */

  h5.subtitle {
    @apply font-fontbase font-normal text-16 leading-20 mdscreen2:text-30 mdscreen2:leading-36 md:text-40 md:leading-46 lg:text-25 lg:leading-26 2xl:text-40 2xl:leading-46;
  }

  .content.small {
    @apply font-fontbase text-white text-12 leading-16 sm:text-16 mdscreen2:text-16 mdscreen2:leading-20 md:text-20 md:leading-26 block;
  }

  .footerinformtitle {
    @apply font-heading font-normal text-40 leading-46 md:text-60 md:leading-64;
  }

  .contenttxt p {
    @apply font-fontbase font-medium text-12 leading-16 md:text-16 md:leading-20;
  }

  .content p {
    @apply font-fontbase font-medium text-14 leading-18 sm:text-16 sm:leading-20 md:text-20 md:leading-26 lgscreen:text-16 lgscreen:leading-20 xlscreen:text-16 xlscreen:leading-20 desktop:text-16 desktop:leading-20;
  }

  .content.lg p {
    @apply text-16 leading-20 md:text-20 md:leading-26 lg:text-30 lg:leading-36;
  }

  .content.sm p {
    @apply font-normal text-12 leading-16 xsscreen2:text-14 xsscreen2:leading-18 sm:text-14 sm:leading-18 md:text-16 md:leading-20;
  }

  .slideintitle {
    @apply font-fontbase font-medium text-12 leading-16 xsscreen2:text-16 xsscreen2:leading-20 sm:text-16 sm:leading-20 md:text-14 md:leading-18;
  }

  .globalcontent .content p {
    @apply font-fontbase font-normal xsscreen2:text-16 xsscreen2:leading-20 md:text-20 md:leading-24;
  }

  .linktitle {
    @apply pl-10 md:pl-20 text-16 leading-20 mdscreen:text-30 mdscreen:leading-38 lgscreen:text-20 lgscreen:leading-26 xlscreen:text-20 xlscreen:leading-26 desktop:text-20 desktop:leading-26 2xl:text-30 2xl:leading-38 font-fontbase font-normal;
  }

  .newbxhover .newsubtitle {
    @apply font-fontbase  font-medium text-20 leading-26 xlscreen:text-18 xlscreen:leading-24 desktop:text-18 desktop:leading-24;
  }

  .firmcontent1 p {
    @apply font-fontbase font-normal text-14 leading-18 smscreen:text-16 smscreen:leading-20 md:text-20 md:leading-24 lgscreen:text-16 lgscreen:leading-20 xlscreen:text-16 xlscreen:leading-20 desktop:text-16 desktop:leading-20;
  }

  .ourfirmimagetext {
    @apply absolute left-20px top-50% -translate-y-50% right-50 smscreen:top-50% smscreen:-translate-y-50% smscreen:left-40 smscreen:right-80 mdscreen:top-180 mdscreen:left-40 mdscreen:right-100 lgscreen:top-180 lgscreen:left-40 xlscreen:left-100 xlscreen:top-165 desktop:left-100 desktop:top-165 largedesktop:left-100 largedesktop:top-165 largedesktop2:top-165 largedesktop2:right-180 2xl:top-190 2xl:left-192;
  }

  .ourfirmcontenttext p {
    @apply font-heading font-normal italic text-30 leading-36 smscreen1:text-30 smscreen1:leading-36 md:text-40 md:leading-40  xlscreen:text-30 xlscreen:leading-36 desktop:text-30 desktop:leading-36 largedesktop:text-30 largedesktop:leading-36 largedesktop2:text-30 largedesktop2:leading-36;
  }

  .frimtitles {
    @apply text-20 leading-24 xsscreen2:text-30 xsscreen2:leading-36 smscreen:text-30 smscreen:leading-36 mdscreen:text-40 mdscreen:leading-46 lg:text-30 lg:leading-46 xlscreen:text-30 xlscreen:leading-36 desktop:text-30 desktop:leading-36 largedesktop:text-30 largedesktop:leading-36 2xl:text-40 2xl:leading-46 largedesktop2:text-30 largedesktop2:leading-36;
  }

  .leadingbox {
    @apply left-0 smscreen:left-20px mdscreen:left-40 lgscreen:left-40 lg:left-100 2xl:left-192 largedesktop:left-100;
  }

  .unilisttitle {
    @apply font-fontbase font-medium text-black text-14 leading-18 smscreen:text-16 smscreen:leading-20 md:text-20 md:leading-24;
  }

  .uniquecontent p {
    @apply font-fontbase font-medium text-16 leading-20 md:text-20 md:leading-26;
  }

  .uniquebigtitle {
    @apply xlscreen:text-60 xlscreen:leading-64 desktop:text-60 desktop:leading-64;
  }

  .cookiecontent p {
    @apply font-fontbase font-medium text-12 leading-16 sm:text-14 sm:leading-18 md:text-16 md:leading-20;
  }

  .cookiecontent p a {
    @apply text-slate-500 hover:text-white transition-all duration-300;
  }

  .clienttext {
    @apply font-fontbase font-medium smscreen1:text-14 smscreen1:leading-18 md:text-16 md:leading-20;
  }

  .uniqueicon img {
    @apply smscreen1:w-50 smscreen1:h-50 md:w-70px md:h-70;
  }

  .popupcontent p {
    @apply text-12 leading-16 smscreen:text-14 smscreen:leading-18 md:text-16 md:leading-20 text-black;
  }

  .accordionright .content.xl p,
  .accordionlist p {
    @apply font-fontbase font-normal text-14 leading-18 smscreen:text-16 smscreen:leading-20 md:text-20 md:leading-26;
  }

  .accordionlist p {
    @apply md:mb-40 mb-20px;
  }

  .accordionlist li {
    @apply font-fontbase font-normal text-16 leading-20 smscreen:text-20 smscreen:leading-24 md:text-25 md:leading-30 xlscreen:text-25 xlscreen:leading-30 desktop:text-25 desktop:leading-30;
  }

  .accordionlist li {
    @apply font-fontbase font-normal text-14 leading-18 smscreen:text-16 smscreen:leading-20 md:text-20 md:leading-24;
  }

  .accordianpanel .accordpanelcontent .content.lg p {
    @apply font-heading font-normal text-16 leading-20 smscreen:text-20 smscreen:leading-24 md:text-30 md:leading-36 text-black;
  }

  .leadertitle {
    @apply text-20 leading-24 md:text-30 md:leading-36;
  }

  .leaderposition {
    @apply font-fontbase font-normal text-14 leading-18  md:text-16 md:leading-20;
  }

  .container-fluid {
    @apply smscreen1:px-20 mdscreen:px-40 lgscreen:px-40 xlscreen:px-100 desktop:px-100 largedesktop:px-100 2xl:px-192 pt-20px pb-80 xsscreen2:pt-28px smscreen:pt-28px smscreen:pb-100 mdscreen:py-100 lgscreen:py-80 xlscreen:py-80 desktop:py-100 largedesktop:py-100 2xl:pt-130 2xl:py-306;
  }

  .popuptitle {
    @apply smscreen1:text-40 smscreen1:leading-46;
  }

  /* .popup-content.xl p {

  @apply text-20 leading-24 md:text-30 md:leading-38 mb-40 md:mb-60 lgscreen:mb-40 xlscreen:mb-40 desktop:mb-40 lgscreen:text-20 lgscreen:leading-26 xlscreen:text-20 xlscreen:leading-26 desktop:text-20 desktop:leading-26;

} */

  .popup-content.xl p {
    @apply text-16 leading-20 md:text-20 md:leading-24 mb-40 md:mb-40;
  }

  .popup-content.xl p:last-child {
    @apply lg:mb-0;
  }

  .popuplefttitle {
    @apply font-fontbase font-bold text-black  text-16 leading-20 md:text-20 md:leading-24;
  }

  .popup-header .linktitle {
    @apply smscreen1:text-20 smscreen1:leading-26;
  }

  .careertitle {
    @apply font-heading font-normal text-40 leading-46 sm:text-60 sm:leading-64 md:text-80 md:leading-80 2xl:text-120 2xl:leading-120;
  }

  .postitongrp-right .careertitles {
    @apply text-16 leading-20 md:text-30 md:leading-36;
  }

  .searchjobtitle {
    @apply xlscreen:text-25 xlscreen:leading-30 desktop:text-25 desktop:leading-30;
  }

  .position-content p {
    @apply xsscreen:text-12 xsscreen:leading-16 font-normal;
  }

  .searchleft .content p,
  .searchlright .content p,
  .experiencelist .teamlistcontent .content p {
    @apply xsscreen:text-14 xsscreen:leading-18;
  }

  .searchleft .content p,
  .searchlright .content p,
  .teamlistcontent .content p,
  .position-content p {
    @apply xlscreen:text-16 xlscreen:leading-20 desktop:text-16 desktop:leading-20;
  }

  .carerpoptitles {
    @apply font-fontbase font-medium text-14 leading-18 md:text-16 md:leading-20 lg:text-20 lg:leading-26;
  }

  .popup-contentlist .accord-list li {
    @apply font-fontbase font-medium text-14 leading-18 md:text-16 md:leading-20 lg:text-20 lg:leading-26;
  }

  .formcontent {
    @apply text-gray text-14 leading-18 font-fontbase font-medium;
  }

  .header-bannerbox h1.ourfirmtitle {
    @apply lgscreen:text-50 lgscreen:leading-55 xlscreen:text-60 xlscreen:leading-64 desktop:text-60 desktop:leading-64;
  }

  .tableheading {
    @apply font-fontbase font-medium text-13 leading-18 md:text-14 md:leading-20 xl:text-14 2xl:text-16 lg:leading-26;
  }

  .tableheading3 {
    @apply w-[100px] block;
  }
  .tableheading3 {
    @apply w-[100px] #{!important};
  }

  .tabletitle {
    @apply font-fontbase font-medium text-12 leading-16 md:text-14 md:leading-18 lg:text-16 lg:leading-20;
  }

  .newstag {
    @apply font-fontbase font-medium text-lightblue capitalize text-14 leading-18 md:text-16 md:leading-20 lg:text-20 lg:leading-24 xlscreen:text-16 xlscreen:leading-18 desktop:text-16 desktop:leading-18;
  }

  .newstag-date {
    @apply font-fontbase font-medium text-gray capitalize text-14 leading-18 md:text-16 md:leading-20;
  }

  .newsdetail-subtitle {
    @apply lgscreen:text-20 lgscreen:leading-26 xlscreen:text-20 xlscreen:leading-26 desktop:text-20 desktop:leading-26;
  }

  .newsdetail {
    @apply lgscreen:text-20 lgscreen:leading-26 xlscreen:text-20 xlscreen:leading-26 desktop:text-20 desktop:leading-26 2xl:text-30 2xl:leading-36;
  }

  .clienttext.xl {
    @apply text-20 leading-26;
  }

  .clienttext.sm {
    @apply smscreen1:text-16 smscreen1:leading-20;
  }

  .header-email {
    @apply font-fontbase font-medium text-20 leading-26 lgscreen:text-16 lgscreen:leading-20 xlscreen:text-16 xlscreen:leading-20 desktop:text-16 desktop:leading-20  lg:text-30 lg:leading-36 text-black;
  }

  .contact-contentbox p {
    @apply text-14 leading-18 sm:text-16 sm:leading-20;

    /* @apply lgscreen:text-16 lgscreen:leading-20 xlscreen:text-16 xlscreen:leading-20 desktop:text-16 desktop:leading-20; */
  }

  .contact-address .newstag-date {
    @apply text-12 leading-16 mdscreen:text-14  mdscreen:leading-18 lgscreen:text-16 lgscreen:leading-20 lg:text-16 lg:leading-20;
  }

  .contact-address .popuplefttitle {
    @apply font-heading font-normal lgscreen:text-20 lgscreen:leading-26;
  }

  .contact-address .contactnotitle {
    @apply font-fontbase font-normal text-20 leading-24 lg:text-30 lg:leading-38;
  }

  .big-title {
    @apply font-heading font-normal text-40 leading-46 md:text-40 md:leading-46 lg:text-60 lg:leading-64 2xl:text-120 2xl:leading-120;
  }

  .newsdetail ol {
    @apply list-decimal pl-25;
  }

  .newsdetail ul {
    @apply list-disc pl-25;
  }

  .newsdetail ul li,
  .newsdetail ol li {
    @apply font-fontbase font-normal text-20 leading-24 md:text-30 md:leading-38 lgscreen:text-20 lgscreen:leading-26 xlscreen:text-20 xlscreen:leading-26 desktop:text-20 desktop:leading-26 2xl:text-30 2xl:leading-38 text-black mb-10;
  }
}

@layer components {
  .video_innercontent .bannerintitle br {
    @apply hidden;
  }

  .logo .svgshow,
  .menushide {
    @apply hidden;
  }

  .btn {
    @apply font-fontbase font-medium block text-center relative text-14 leading-18 rounded-full transition duration-300 ease-in-out;
  }

  .btndarkblue {
    @apply text-darkblue border border-darkblue max-w-125 hover:bg-darkblue hover:text-white transition-all duration-300;
  }

  .whitebtns {
    @apply border border-white text-white smscreen1:max-w-125 hover:bg-white hover:text-darkblue transition-all duration-300;
  }

  .btngray {
    @apply border border-lightgray text-lightgray smscreen1:max-w-125 hover:border-black hover:text-black transition-all duration-300;
  }

  .btnblue {
    @apply bg-lightblue mdscreen1:px-10 mdscreen1:py-10 md:px-20 md:py-15 text-white;
  }

  .search-btn {
    @apply border border-lightblue px-20 py-15 text-lightblue hover:bg-lightblue hover:text-white transition-all duration-300;
  }

  .submitbtn {
    @apply text-black text-16 leading-20 absolute right-10 top-50% -translate-y-[50%] flex items-center;
  }

  .submitbtn i {
    @apply pl-5 text-22;
  }
}

@layer utilities {
  /*Menu Style  */

  .menufixed {
    /* position: fixed !important; */

    @apply z-999 bg-white transition-all duration-500 ease-in-out fixed shadow-3xl;

    animation-name: stickySlideDown;

    animation-name: stickySlideDown;

    animation-duration: 0.5s;

    animation-duration: 0.5s;

    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25); */
  }
  .menufixed {
    @apply fixed #{!important};
  }

  @-webkit-keyframes stickySlideDown {
    0% {
      opacity: 0.7;

      transform: translateY(-100%);
    }

    to {
      opacity: 1;

      transform: translateY(0);
    }
  }

  @keyframes stickySlideDown {
    0% {
      opacity: 0.7;

      transform: translateY(-100%);
    }

    to {
      opacity: 1;

      transform: translateY(0);
    }
  }

  .headerbanner .menufixed {
    /* position: fixed !important; */

    @apply fixed #{!important};
  }

  .menufixed .sidebar {
    @apply pt-[35px];
  }

  .menufixed .svgshow {
    @apply h-55;
  }

  .sidebar ul li:first-child {
    @apply hidden;
  }

  .sidebar ul li:last-child {
    @apply pr-0 #{!important};
  }

  .sidebar ul li a {
    @apply text-white hover:text-white/70 relative;
  }

  .sidebar ul li a::after {
    content: '';

    @apply absolute w-0 h-3 transition-all -bottom-2 left-0 duration-300 ease-in-out bg-lightblue;
  }

  .sidebar ul li a:hover::after {
    @apply w-full;
  }

  .header-main .logo {
    @apply transition-all duration-300 ease-in-out;
  }

  .header-main .menufixed ul li a {
    @apply text-extdarkblue;
  }

  .bodybarfixed {
    @apply overflow-hidden;
  }

  .bodybarfixed .header-video_content {
    @apply -z-1;
  }

  .bodybarfixed .brandslider {
    @apply hidden;
  }

  .sidebarIconToggle {
    @apply absolute h-full w-full hidden cursor-pointer box-border transition-all duration-300 ease-in-out z-999 top-22 right-27 w-50 h-21.29;
  }

  .header-inner__box .sidebarIconToggle .spinner {
    @apply bg-black;
  }

  .header-inner__box .newddatile-banner::before {
    @apply z-auto bg-transparent relative;
  }

  .header-inner__box .headinnermenu .sidebar ul li a {
    @apply lg:text-black;
  }

  .spinner {
    @apply bg-white w-full transition-all duration-300 ease-in-out box-border absolute mx-auto block h-2;
  }

  .spinner:nth-child(1),
  .spinner:nth-child(2) {
    @apply w-50;
  }

  .diagonal.part-1 {
    @apply relative box-border transition-all duration-300 ease-in-out;
  }

  .diagonal.part-2 {
    @apply relative box-border transition-all duration-300 ease-in-out mt-9;
  }

  .diagonal.part-3 {
    @apply relative box-border transition-all duration-300 ease-in-out mt-9;
  }

  .sidebarclose .diagonal.part-1 {
    @apply box-border transition-all duration-300 ease-in-out mt-10;

    transform: translateY(1px) rotateZ(45deg);
  }

  .sidebarclose .horizontal {
    @apply box-border transition-all duration-300 ease-in-out opacity-0;
  }

  .sidebarclose .diagonal.part-2 {
    @apply box-border transition-all duration-300 ease-in-out -mt-11;

    transform: translateY(10px) rotateZ(-45deg);
  }

  .sidebarclose .diagonal.part-3 {
    @apply opacity-0;
  }

  .logo .svgshow,
  .menushide {
    @apply hidden;
  }

  /* Slider Logo */

  .swip_logo img {
    @apply h-[160px];
  }

  /* video style */
  /*
  video::-webkit-media-controls {
    display: none !important;
  }
  */
  .headervideo {
    @apply h-[calc(100vh_-205px)] sm:h-[calc(100vh-120px)] md:md:h-[calc(100vh-195px)] lg:h-[calc(100vh-205px)] xlscreen:h-[calc(100vh-140px)] desktop:h-[calc(100vh-170px)] 2xl:h-[calc(100vh-_170px)];
  }

  .headerglob_banner .headervideo {
    @apply h-screen xsscreen2:h-[480px] sm:h-screen lg:h-screen xlscreen:h-screen desktop:h-full 2xl:h-full;
  }

  .news-insight_video .headervideo {
    @apply h-[330px] sm:h-560 md:h-600 lg:h-500;
  }

  .headerbanner::before {
    content: '';

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

    @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full z-1;
  }

  .video-left {
    @apply relative;
  }

  .video-left::before {
    content: '';

    @apply bg-white absolute w-full h-1 left-0 right-0;
  }

  /* Banner-section */

  .abouttitle::before {
    content: '';

    background: url('../assets/images/bannerline.jpg') no-repeat;

    @apply bg-cover mdscreen1:bg-center z-10 xssmall1:h-35 xsscreen2:h-35 h-65 extlargedesktop1:h-100 absolute left-0 xssmall:top-[47.2%] xssmall2:top-[49%] xsscreen2:top-[45%] xsscreen3:top-[42%] smscreen:top-[47%] mdscreen:top-[47%] lgscreen:top-[37%] xlscreen:top-40% desktop:top-[37%] largedesktop:top-[41%] top-45% extlargedesktop1:top-47% translate-y-1% w-full;

    /* @apply bg-cover extlargedesktop1:bg-[length:100%_56px] mdscreen1:bg-center xssmall1:h-35 xsscreen2:h-35 h-65 z-[10] extlargedesktop1:h-56 absolute left-0 xssmall:top-[47.2%] xssmall2:top-[49%] xsscreen2:top-[42%] xsscreen3:top-[43%] smscreen:top-[43%] mdscreen:top-[37%] lgscreen:top-[37%] xlscreen:top-40% desktop:top-[37%] largedesktop:top-[41%] top-45% extlargedesktop1:top-47% translate-y-1% w-full; */
  }

  /* capabilit_section  */

  .capabilitilist {
    @apply border-t border-solid border-extlightgray transition-all duration-200 ease-in-out py-10 md:pt-20 md:pb-29 lgscreen:py-10 xlscreen:py-10 desktop:py-10;
  }

  .capbili-title span img {
    @apply w-30px h-30 md:w-50 md:h-50 lgscreen:w-40px lgscreen:h-[40px] xlscreen:w-40px xlscreen:h-[40px] desktop:w-40px desktop:h-[40px] 2xl:w-50 2xl:h-50;
  }

  .capabilitilist:hover {
    @apply border-lightblue;
  }

  .capabtabgroup a {
    @apply text-darkblue;
  }

  .capabtabgroup a:hover .capbili-title span img {
    filter: invert(38%) sepia(55%) saturate(1537%) hue-rotate(193deg)
      brightness(101%) contrast(106%);

    @apply text-lightblue;
  }

  .angelicons:after {
    font-family: 'Font Awesome 5 pro';

    content: '\f105';

    @apply text-20 md:text-30 pr-15;
  }

  /* SWIPER SLIDER  */

  .globalSwiper {
    @apply xssmall:pb-40 xsscreen2:pb-68 smscreen:pb-68 #{!important};
  }

  .globalleft img {
    @apply h-210 xsscreen2:h-260 smscreen:h-380 mdscreen:h-500 lgscreen:h-600 xlscreen:h-600 desktop:h-600 2xl:h-800;
  }

  .globalright {
    @apply lgscreen:py-80 xlscreen:py-80 desktop:py-80 2xl:py-120;
  }

  .globalslide {
    @apply max-h-max lgscreen:h-600 xlscreen:h-600 desktop:h-600 2xl:h-800;
  }

  .globalSwiper .globalpagination {
    @apply flex justify-start items-end text-white absolute top-auto xsscreen:bottom-64 smscreen:bottom-64 mdscreen:bottom-70 lgscreen:bottom-80 xlscreen:bottom-80 desktop:bottom-80 2xl:bottom-120 md:left-[20px] lg:left-0 text-left pl-20 lgscreen:pl-40 xlscreen:pl-100 desktop:pl-100 2xl:pl-192 w-20 h-46;
  }
  .globalSwiper .globalpagination {
    @apply xsscreen:bottom-64 #{!important};
  }

  .globalSwiper .globalnext::after,
  .globalSwiper .globalprev::after,
  .newsswiper .newsnext::after,
  .newsswiper .newsprev::after {
    @apply text-25;
  }

  .globalSwiper .prev_nextmain {
    @apply absolute top-auto md:left-auto lg:left-55% xl:left-[53%] 2xl:left-45% right-0 md:right-40 lg:right-auto bottom-[74px]  xsscreen2:bottom-[calc(100%_-_90.5%)] sm:bottom-[calc(100%_-_91.5%)] md:bottom-[calc(100%_-_91.5%)] lg:bottom-[calc(100%_-_84%)] z-10000 w-100 lg:w-[100px] desktop2:w-100 mx-auto text-white transition-all duration-300 ease-in-out;
  }

  .globalSwiper .globalprev {
    @apply left-0 right-auto;
  }

  .globalSwiper .globalnext {
    @apply right-0;
  }

  .globalSwiper .globalnext:hover,
  .globalSwiper .globalprev:hover {
    @apply text-lightblue;
  }

  .globalSwiper .swiper-pagination-current {
    @apply font-fontbase font-normal w-auto text-20 leading-24 md:text-30 md:leading-30;
  }

  .globalSwiper .swiper-pagination-total {
    @apply font-fontbase font-medium text-12 leading-16 md:text-16 md:leading-20 w-25;
  }

  .swiper-fractionbox .swiper-pagination-current {
    @apply flex items-baseline;
  }

  .swiper-fractionbox .swiper-pagination-current::after {
    content: '';

    @apply w-37px md:w-80 h-1 bg-gray block my-10 mb-5px smscreen1:mb-5px mx-10;
  }

  .globalSwiper .swiper-pagination-current::before,
  .globalSwiper .swiper-pagination-total::before {
    content: '0';
  }

  .globalright {
    @apply bg-transparent;
  }

  /* News Slider */

  .newbxhover:hover,
  .newbxhover:hover .newsdate,
  .newbxhover:hover .newsbtn {
    @apply text-lightblue border-lightblue transition-all duration-300 ease-in-out;
  }

  .newsswiper .newsnext,
  .newsswiper .newsprev {
    @apply top-auto bottom-0 text-extlightgray transition-all duration-300 ease-in-out;
  }

  .newsswiper .newsprev {
    @apply left-auto right-80;
  }

  .newsswiper .newsnext:hover,
  .newsswiper .newsprev:hover {
    @apply text-darkblue;
  }

  .footerleftbox ul li a:hover,
  .socialmedia a:hover {
    @apply transition-all duration-300 ease-in-out;

    color: rgba(255, 255, 255, 0.7);
  }

  .socialmedia a {
    @apply text-white;
  }

  /* footer */

  .footerleftbox ul li:last-child {
    @apply mb-0;
  }

  .footerleftbox ul li a {
    @apply font-fontbase font-medium smscreen1:text-16 smscreen1:leading-20  sm:text-16 sm:leading-20 md:text-16 md:leading-20 text-white;
  }

  .footer-bottom ul li:last-child {
    @apply xssmall:-order-1 xssmall2:-order-1 xssmall:mb-22 xssmall2:mb-22;
  }

  .mc4wp-form-fields .formfield {
    @apply p-10 border-t border-l border-extlightgray bg-transparent;
  }

  .footerfrm .formfield::placeholder,
  .mc4wp-form-fields .formfield:focus,
  .mc4wp-form-fields .footerfrm .formfield::placeholder {
    @apply text-black;
  }

  .footerfrm .formfield:focus,
  .footerfrm .formfield:focus-visible {
    @apply shadow-none outline-none bg-transparent;
  }

  .footer-bottom ul li:last-child {
    @apply md:pl-20;
  }

  .mc4wp-error p {
    @apply font-fontbase font-medium text-black;
  }

  .input-wrapper {
    @apply relative leading-16;
  }

  .formlable {
    @apply text-black font-fontbase font-medium absolute capitalize z-9 top-12 left-5px px-2 pointer-events-none bg-white transition-all duration-300 ease-in-out translate-y-0;
  }

  .formfield {
    @apply text-black border border-black text-14 leading-18 md:text-16 md:leading-20 font-fontbase font-medium p-10 h-40 w-full border-extlightgray relative;
  }

  .formfield:invalid + .formlable {
    @apply translate-y-0;
  }

  .wpcf7-form label.has-value,
  .mc4wp-form label.has-value {
    color: #07122b #{!important};

    @apply -translate-y-20px text-10 leading-14 md:text-12 md:leading-16;
  }

  .formfield:focus {
    @apply border-extlightgray;
  }

  .formfield:focus + .formlable {
    @apply text-black -translate-y-20px text-10 leading-14 md:text-12 md:leading-16;
  }

  /* firm page */

  .uniquelisted {
    @apply border-r border-extlightgray;
  }

  .uniquelisted a {
    @apply p-20 border-extlightgray border-b block w-full h-full bg-white transition-all duration-300 ease-in-out;
  }

  .uniquelisted:nth-child(8) a {
    @apply rounded-br-58;
  }

  .uniquelisted a:hover {
    @apply border border-extlightgray relative -translate-x-10 -translate-y-10 rounded-br-60;
  }

  .uniquelisted:nth-child(8) a:hover {
    @apply -translate-x-0 -translate-y-0;
  }

  .uniquelisted:nth-child(4),
  .uniquelisted:nth-child(8) {
    @apply border-r-0;
  }

  .uniquelisted:nth-child(2),
  .uniquelisted:nth-child(6) {
    @apply mdscreen1:border-r-0;
  }

  .uniquelisted:nth-child(5) a,
  .uniquelisted:nth-child(6) a,
  .uniquelisted:nth-child(7) a,
  .uniquelisted:nth-child(8) a {
    @apply lg:border-b-1 lg:border-b-transparent;
  }

  .uniquelisted:nth-child(7) a,
  .uniquelisted:nth-child(8) a {
    @apply mdscreen1:border-b-1 lg:border-b-transparent;
  }

  .uniquelisted:nth-child(8) a:hover {
    @apply border-0 top-0 left-0;
  }

  .uniquelisted:nth-child(5) a:hover,
  .uniquelisted:nth-child(6) a:hover,
  .uniquelisted:nth-child(7) a:hover {
    @apply border-b-1 border-b-extlightgray;
  }

  .uniqexpandleft {
    @apply lg:h-[515px] overflow-auto;
  }

  .mapcontent {
    @apply top-35% left-0 right-0 mx-auto;
  }

  /* Capabilities ACCORDION */

  .accordion:after {
    font-family: 'Font Awesome 5 pro';

    content: '\f067';

    @apply font-normal text-black text-12 md:text-25 leading-30 absolute top-50% right-0 -translate-y-50% rotate-0;
  }

  .capabtabgroup a.active:after {
    content: '\f067';

    @apply rotate-45;
  }

  .capabtabgroup .active .capabilitilist,
  .capabtabgroup .active:after,
  .capabtabgroup .active .capabilitilist .capbili-title span img {
    @apply border-lightblue text-lightblue;
  }

  .capabtabgroup .active .capabilitilist .capbili-title span img {
    filter: invert(38%) sepia(55%) saturate(1537%) hue-rotate(193deg)
      brightness(101%) contrast(106%);
  }

  .accordionlist li {
    @apply relative pl-30 smscreen:pl-40 md:pl-60 mb-20px md:mb-10;
  }

  .accordionlist li::before {
    font-family: 'FontAwesome';

    content: '';

    @apply bg-lightblue w-20px smscreen:w-20px xsscreen:top-8 smscreen:top-10  md:w-30px lg:w-40px h-2 inline-block align-middle absolute left-0 top-16;
  }

  .accordpanelcontent .content.lg p {
    @apply mb-20px font-heading;
  }

  .capabtabgroup .active:after {
    @apply text-lightblue;
  }

  .capabtabgroup a:hover,
  .capabtabgroup a:hover::after,
  .capabilitilist:hover {
    @apply text-lightblue;
  }

  /* people pages */

  .listbox li a .teamlistcontent:hover {
    @apply border-lightblue;
  }

  .leaderleft:hover a,
  .leaderright:hover a {
    @apply border-lightblue;
  }

  .listbox li a:hover .leadertitle,
  .leaderleft:hover .leadertitle,
  .leaderright:hover .leadertitle,
  .leaderleft:hover .leaderposition,
  .leaderright:hover .leaderposition {
    @apply text-lightblue;
  }

  /* popup slider */

  .popupbody {
    @apply overflow-hidden;
  }

  .swiperaerogrp .popup-next,
  .swiperaerogrp .popup-prev,
  .swiperaerogrp .popup-next:after,
  .swiperaerogrp .popup-prev:after {
    @apply w-14 h-25 text-black hover:text-black/60 text-20;
  }

  .swiperaerogrp .popup-prev {
    @apply left-0;
  }

  .swiperaerogrp .popup-next {
    @apply right-45;
  }

  /* custom scrollbar design */

  /* width */

  .leaderpopupmodel::-webkit-scrollbar {
    @apply w-[5px];
  }

  /* Track */

  .leaderpopupmodel::-webkit-scrollbar-track {
    @apply bg-black;
  }

  /* Handle */

  .leaderpopupmodel::-webkit-scrollbar-thumb {
    @apply bg-lightblue;
  }

  .leaderpopupmodel 

  /* Careers page */

  .searchleft::before {
    content: '';

    @apply bg-extlightgray smscreen1:h-1 smscreen1:top-auto smscreen1:bottom-0 smscreen1:left-60 smscreen1:right-60 mdscreen:h-1 mdscreen:top-auto mdscreen:bottom-0 mdscreen:left-60 mdscreen:right-60 lg:w-1px absolute lg:top-22 lg:bottom-22 lg:right-0;
  }

  .experiencelist .teamlistcontent:hover .popuplefttitle {
    @apply text-lightblue;
  }

  .teamlistbox .teamlistcontent:hover {
    @apply text-lightblue;
  }

  .experiencelist .teamlistcontent:hover {
    @apply border-lightblue;
  }

  .popup-contentlist,
  .popup-contentlist ul li {
    @apply mb-24 text-black font-fontbase font-medium text-14 leading-18 md:text-16 md:leading-20 lg:text-20 lg:leading-26;
  }

  .popup-contentlist ul li::before {
    @apply mdscreen1:top-7 md:top-8 top-12;
  }

  .popup-socialmedia a {
    @apply mr-43 md:mr-50 text-black text-25 hover:text-black/50 transition-all duration-300 ease-in-out;
  }

  .popup-socialmedia a:last-child {
    @apply mr-0;
  }

  .careerbtn_grp .linkbtn .whitebtns {
    @apply text-14 leading-18 md:text-20 md:leading-26 font-fontbase font-medium;
  }

  .careerbtn_grp .linkbtn:nth-child(2) {
    @apply relative;
  }

  .careerbtn_grp .linkbtn:nth-child(2)::before {
    content: '';

    @apply bg-white w-1 h-full absolute top-0 left-0;
  }

  /* cookie-policy */

  .newsdetailvideo-box {
    @apply relative pt-100% md:pt-[48.25%];
  }

  .newsdetailvideo-box iframe {
    @apply absolute top-0 left-0 w-full h-full block;
  }

  /* Transactoin  */

  .transation_benner .headervideo_banner img {
    @apply h-[330px] xsscreen2:h-[330px] sm:h-560 md:h-560 lg:h-500 object-cover;
  }

  /* TABLE */

  .table.dataTable thead .sorting:not(.table.dataTable thead .sorting_asc) {
    @apply hidden;
  }

  .table-main .dataTables_wrapper .dataTables_filter {
    @apply lg:w-full mb-50 float-none;
  }
  .table-main .dataTables_wrapper .dataTables_filter {
    @apply mb-50 float-none #{!important};
  }

  .table-main .dataTables_wrapper .dataTables_filter input {
    @apply border-t-0 border-l-0 border-r-0 rounded-0 w-full relative py-18 px-50 m-0 text-16 leading-20 lg:text-20 lg:leading-26;
  }

  .table-main .dataTables_wrapper .dataTables_filter label {
    @apply relative;
  }

  #example_paginate {
    @apply hidden;
  }

  .dataTables_wrapper .dataTables_filter input {
    @apply w-full;
  }

  table.display.tablelist.w-1000.lg\:w-full.dataTable.no-footer {
    width: 100% #{!important};
  }

  .tabelmain-section table.dataTable thead th,
  .tabelmain-section table.dataTable thead td {
    @apply py-10 pl-10 pr-20px;
  }

  .table-main .dataTables_wrapper .dataTables_filter {
    @apply lg:w-full mb-50 float-none;
  }

  .table-main .dataTables_wrapper .dataTables_filter input {
    @apply border-t-0 border-l-0 border-r-0 rounded-0 w-full relative py-18 px-50 m-0 text-16 leading-20 lg:text-20 lg:leading-26;
  }

  .table-main .dataTables_wrapper .dataTables_filter label {
    @apply relative;
  }

  .table-main .dataTables_wrapper .dataTables_filter label::after {
    font-family: 'Font Awesome 5 pro';

    content: '\f002';

    color: #acb2bc;

    @apply absolute -top-2 left-18 cursor-pointer;
  }

  #example_paginate {
    @apply hidden;
  }

  .dataTables_wrapper .dataTables_filter input {
    @apply w-full max-w-full min-w-full;
  }

  /* news page */

  .searchbtns ::after {
    font-family: 'Font Awesome 5 pro';

    content: '\f002';

    @apply absolute top-0 left-18 cursor-pointer text-lightgray;
  }

  .news-gallerylist li {
    @apply font-fontbase font-normal relative cursor-pointer xsscreen:font-medium smscreen:font-medium text-lightgray text-20 leading-26 md:text-30 md:leading-38 lgscreen:text-16 lgscreen:leading-20 xlscreen:text-16 xlscreen:leading-20 desktop:text-20 desktop:leading-26;
  }

  .news-gallerylist li:hover {
    @apply text-extdarkblue;
  }

  .news-gallerylist li.active {
    @apply text-lightblue relative cursor-pointer;
  }

  .news-gallerylist li.active::after,
  .news-gallerylist li:hover::after {
    content: '';

    @apply w-full h-1 inline-block absolute -bottom-1 smscreen:-bottom-1 lgscreen:-bottom-11 xlscreen:-bottom-11 desktop:-bottom-8 2xl:-bottom-1 left-0 right-0;
  }

  .news-gallerylist li.active::after,
  .news-gallerylist li.active:hover::after {
    @apply bg-lightblue;
  }

  .news-gallerylist li:hover::after {
    @apply bg-extdarkblue;
  }

  .newsgrid-gallery .newsitem,
  .archive-items .newsitem {
    @apply overflow-hidden hidden;
  }

  .newsgrid-gallery .show,
  .archive-items .show {
    @apply flex;
  }

  .newsswiper .swiper-slide,
  .newsswiper1 .swiper-slide {
    @apply h-auto;
  }

  /* cONTACT PAGE */

  .contact-grp .formfield,
  .contact-grp .formlable {
    @apply bg-lightbgcolor;
  }

  .contactbody {
    @apply overflow-hidden;
  }

  .header_email-text a {
    @apply text-16 leading-20 2xl:text-[24px] 2xl:leading-[28px] font-fontbase font-medium;
  }

  .ctncountry-heading {
    @apply text-20 leading-24 xl:text-24 xl:leading-28 text-black font-fontbase font-normal;
  }

  .ctncountry-subheading {
    @apply text-16 md:text-20 leading-20 text-black font-fontbase font-normal;
  }

  .contactlist {
    @apply mb-16;
  }

  .accordionlist.contactlist li {
    @apply text-16 leading-20 md:text-20 md:leading-24 pl-35 xl:pl-50 mb-10 lg:mb-20px;
  }

  .accordionlist.contactlist li:before {
    @apply w-20px xl:w-[30px] top-50% -translate-y-50%;
  }

  .desktop-left .contactlist {
    @apply hidden lg:block;
  }

  .mobile-left {
    @apply block lg:hidden;
  }

  .contact-left::-webkit-scrollbar {
    @apply w-5px;
  }

  .contact-left::-webkit-scrollbar-track {
    @apply bg-black;
  }

  .contact-left::-webkit-scrollbar-thumb {
    @apply bg-lightblue;
  }
}

@screen lgdesktop1 {
  .leadingbox {
    @apply -mt-5%;
  }
}

@screen lgdesktop {
  .leadingbox {
    @apply -mt-10%;
  }

  .aboutbanner-box .abouttitle {
    @apply max-w-2000;
  }
}

@screen desktop1 {
  .globalright {
    @apply px-100;
  }

  .brandslidebox .swiper-slide img {
    @apply max-w-170 w-full object-contain;
  }

  .swip_logo img {
    @apply h-auto;
  }
}

@screen desktop {
  .globalslide .globalright {
    @apply px-100;
  }

  .leadingbox {
    @apply max-w-900;
  }

  .leadbox {
    @apply max-w-220;
  }

  .brandslider {
    @apply pb-50;
  }

  .content.lg p {
    @apply text-25 leading-30;
  }

  .capbili-title span svg,
  .angelicons svg {
    @apply w-30px h-30;
  }

  .subtitle h3 {
    @apply text-40 leading-46;
  }

  .content.xl p {
    @apply text-20 leading-30;
  }

  .newbxhover h5 {
    @apply text-20 leading-30;
  }

  .footerfrm .footerinformtitle {
    @apply max-w-200;
  }

  .footer-mid {
    @apply pb-70;
  }

  /* our people popup style */

  .popupleft .title .bannerintitle {
    @apply pb-10;
  }
}

@screen xlscreen {
  .globalslide .globalright {
    @apply px-100;
  }

  .leadingbox {
    @apply max-w-900;
  }

  .leadbox {
    @apply max-w-220;
  }

  .title .ourfirmtitle {
    @apply pb-0;
  }

  .brandslider {
    @apply pb-50 pt-10;
  }

  .content.lg p {
    @apply text-25 leading-30;
  }

  .abtcontent {
    @apply max-w-475;
  }

  .capbili-title span svg,
  .angelicons svg {
    @apply w-30px h-30;
  }

  .subtitle h3 {
    @apply text-40 leading-46;
  }

  .content.xl p {
    @apply text-20 leading-30;
  }

  .newbxhover h5 {
    @apply text-20 leading-30;
  }

  .footerfrm .footerinformtitle {
    @apply max-w-200;
  }

  .footer-mid {
    @apply pb-70;
  }
}

@screen lgscreen {
  .leadingbox {
    @apply max-w-900;
  }

  .leadbox {
    @apply max-w-220;
  }

  .brandslider {
    @apply pb-50;
  }

  .content.lg p {
    @apply text-25 leading-30;
  }

  .abtcontent {
    @apply max-w-475;
  }

  .capbili-title span svg,
  .angelicons svg {
    @apply w-30px h-30;
  }

  .subtitle h3 {
    @apply text-40 leading-46;
  }

  .content.xl p {
    @apply text-20 leading-30;
  }

  .newbxhover h5 {
    @apply text-20 leading-30;
  }

  .footerfrm .footerinformtitle {
    @apply max-w-200;
  }

  .footer-mid {
    @apply pb-70;
  }

  /* our people popup slider  */

  .popupleft .title .uniquebigtitle {
    @apply text-60 leading-64;
  }
}

@screen lgscreen1 {
  .globalslide .globalright {
    @apply px-40;
  }

  .uniqexpandright img {
    @apply h-[410px] object-cover;
  }
}

@screen mdscreen1 {
  .header_top .logo a img.svghide {
    @apply h-100;
  }

  nav .sidebar ul li {
    @apply px-0 #{!important};
  }

  .headinnermenu .sidebar ul li a {
    @apply text-white;
  }

  .globalleft img {
    @apply object-top;
  }

  .globalright {
    @apply pt-52 pb-68;
  }

  .header-main .leadingbox {
    @apply max-w-90;
  }

  .menufixed .spinner {
    @apply bg-darkblue;
  }

  .sidebarIconToggle,
  .sidebar ul li:first-child {
    @apply block;
  }

  .sidebarclose .spinner {
    @apply w-25;
  }

  .sidebar {
    @apply hidden;
  }

  .bodybarfixed {
    @apply overflow-hidden;
  }

  .bodybarfixed .sidebar {
    @apply block pl-150 overflow-auto;
  }

  .bodybarfixed .menushide {
    @apply block absolute top-24 -left-90 right-0 text-center;
  }

  .bodybarfixed .sidebarIconToggle .spinner {
    @apply bg-white;
  }

  .bodybarfixed nav {
    @apply w-full justify-start smscreen:justify-center mdscreen:justify-center absolute top-0 left-0 h-screen items-center z-999 flex-col bg-primary;
  }

  .bodybarfixed .menunavbar,
  .sidebaranimat ul {
    @apply flex-col w-full pt-80;
  }

  .sidebaranimat ul li a {
    @apply text-40 leading-46 py-13 block text-white;
  }
  .sidebaranimat ul li a {
    @apply text-40 leading-46 #{!important};
  }

  .sidebaranimat ul li a:hover {
    @apply pl-0;

    color: rgba(255, 255, 255, 0.7) #{!important};
  }

  .menufixed .sidebaranimat ul li a {
    @apply text-white;
  }

  .capbili-title span svg,
  .angelicons svg {
    @apply w-30px h-30;
  }

  .newsswiper .newsnext,
  .newsswiper .newsprev {
    @apply bottom-3;
  }

  /* our people popup slider  */

  .swiperaerogrp .popup-next {
    @apply right-0;
  }

  .swiperaerogrp .popup-prev {
    @apply left-auto right-45;
  }

  .uniqexpandright img {
    @apply min-h-full;
  }
}

@screen mdscreen2 {
  .content.head_video-content p {
    @apply text-16 leading-20;
  }
}

@screen smscreen1 {
  .menushide {
    @apply pl-60;
  }

  .sidebaranimat ul li a {
    @apply text-30 leading-36 #{!important};
  }

  .angelicons svg {
    @apply w-20px;
  }

  .globalslide .globalright {
    padding-left: 20px #{!important};

    padding-right: 20px #{!important};
  }

  .header-main .leadingbox {
    @apply -mt-15%;
  }

  .brandslidebox {
    @apply pb-56;
  }

  .brandslidebox .swiper-slide img {
    @apply max-w-165 w-full object-contain;
  }

  .swip_logo img {
    @apply h-auto;
  }

  .globalSwiper .globalpagination {
    @apply pl-20px;
  }

  .globalSwiper .globalnext {
    @apply right-20px;
  }

  .globalSwiper .globalnext::after,
  .globalSwiper .globalprev::after,
  .newsswiper .newsnext::after,
  .newsswiper .newsprev::after {
    @apply text-20;
  }

  .footerfrm {
    @apply pb-89;
  }

  .socialmedia a svg {
    @apply w-15;
  }

  /* OUR FIRM */

  .ourfrimrightcontent .frimtitle {
    @apply max-w-full;
  }

  .uniqexpandright {
    @apply border-t border-extlightgray;
  }

  .accordpanelcontent .content.lg p {
    @apply text-16 leading-20;
  }

  .footer-menu {
    @apply pl-0 #{!important};
  }
}

@screen smscreen {
  .brandslidebox .swiper-slide img {
    @apply max-w-[100px] w-full object-contain;
  }
}

@screen xsscreen {
  .sidebarclose {
    @apply top-15;
  }

  .header-main .leadingbox {
    @apply max-w-96;
  }

  .sidebaranimat ul li a {
    @apply text-20 leading-26 #{!important};
  }

  .bodybarfixed .menushide {
    @apply left-0 text-left pl-20;
  }

  .bodybarfixed .sidebar {
    @apply w-full pl-20;
  }

  .socialmedia svg {
    @apply w-20px;
  }

  .footerfrm {
    @apply pb-89;
  }

  .footer-mid .footer-menu {
    @apply flex-col;
  }

  .globalcontent .content.xl p {
    @apply text-16 leading-20;
  }

  .brandslidebox .swiper-slide img {
    @apply max-w-[100px] w-full object-contain;
  }

  /* OUR FIRM */

  .position-content p {
    @apply font-medium;
  }

  .uniqexpandright img {
    @apply h-350 object-cover;
  }

  .content.head_video-content p {
    @apply text-14 leading-18;
  }

  /* new version style  */

  .video-inner_left .subtitle,
  .video-inner_right .subtitle {
    @apply text-16 leading-20;
  }

  .video_innercontent .bannerintitle br {
    @apply block;
  }

  .video-inner_left .content.small,
  .video-inner_right .content.small {
    @apply text-12 leading-16;
  }
}

@screen xssmall1 {
  .header-main .leadingbox {
    @apply -mt-25%;
  }

  .footerfrm .capbilititle {
    @apply max-w-160;
  }

  .footerfrm .content {
    @apply max-w-280;
  }
}

@screen xssmall2 {
  .cookiecontent p br {
    @apply hidden;
  }
}

@screen xssmall {
  .header-main .leadingbox {
    @apply -mt-40%;
  }

  .leadingbox .leadbox {
    @apply max-w-130 w-full mr-20px;
  }

  .sidebarIconToggle,
  .spinner:nth-child(1),
  .spinner:nth-child(2) {
    @apply w-30px;
  }

  .footer-mid .footmenumain {
    @apply flex-row;
  }

  .footer-menu .footerleftbox1 ul li:last-child {
    @apply mb-0;
  }

  .footerfrm .capbilititle {
    @apply max-w-160;
  }

  .footerfrm .content {
    @apply max-w-280;
  }

  .footer-top .xl-container-fluid {
    @apply px-0;
  }

  .cookiecontent p br {
    @apply hidden;
  }
}

@screen xxs-small {
  .clientimages img {
    @apply w-60 h-60;
  }

  .clienttitle {
    @apply pl-50;
  }
}

@media (max-width: 1536px) {
  nav .sidebar ul li {
    @apply px-18;
  }
}

@media (max-width: 1440px) {
  nav .sidebar ul li {
    @apply px-14;
  }
}

@media (max-width: 1366px) {
  nav .sidebar ul li {
    @apply px-8;
  }

  nav .sidebar ul li a {
    @apply text-13;
  }
}

/* CONTACT FORM  and CAREER PAGE POPUP FORM */

.popup-form .wpcf7-form {
  @apply max-w-full md:max-w-576;
}

.popup-contentlist,
.popup-contentlist ul li {
  @apply mb-24 text-black font-fontbase font-medium text-14 leading-18 md:text-16 md:leading-20 lg:text-20 lg:leading-26;
}

.input-wrapper {
  @apply relative leading-16 inline-block mb-40;
}

.formlable {
  @apply text-black font-fontbase font-medium absolute capitalize z-9 top-12 left-5px px-2 pointer-events-none bg-white transition-all duration-300 ease-in-out translate-y-0;
}

.formfield {
  @apply text-black text-14 leading-18 md:text-16 md:leading-20 font-fontbase font-medium p-10 h-40 w-full border border-extlightgray relative;
}

.formfield:invalid + .formlable {
  @apply translate-y-0;
}

.wpcf7-form label.has-value {
  @apply text-black -translate-y-20px text-10 leading-14 md:text-12 md:leading-16;
}

.formfield:focus {
  @apply border-extlightgray;
}

.formfield:focus + .formlable {
  @apply text-black -translate-y-20px text-10 leading-14 md:text-12 md:leading-16;
}

.contact-right .formfield,
.contact-right .formlable {
  @apply bg-lightbgcolor;
}

.formtextarea,
.wpcf7-textarea {
  @apply h-80;
}

.formfield:invalid + .formlable {
  @apply translate-y-0;
}

.wpcf7-form label.has-value,
.wpcf7-form label.has-value {
  @apply -translate-y-20px text-10 leading-14;
}

.formfield:focus + .formlable {
  @apply -translate-y-20px text-10 leading-14;
}

.wpcf7-submit {
  @apply font-fontbase font-medium block text-center smscreen1:text-14  smscreen1:leading-18 md:text-20 md:leading-26 rounded-full;
}

.wpcf7-submit {
  @apply text-darkblue border w-full h-50 border-darkblue smscreen1:max-w-150 hover:bg-darkblue hover:text-white transition-all duration-300;
}

.formcontent {
  @apply font-fontbase font-medium text-14 leading-18 xsscreen:max-w-355 max-w-455 xsscreen:pt-20 xsscreen:pl-0 pl-60;
}

.formcontent a {
  @apply text-black hover:text-black/50 transition-all duration-300 ease-in-out;
}

.wpcf7-not-valid-tip,
.wpcf7-response-output {
  @apply text-16 font-medium font-fontbase;
}

.wpcf7-not-valid-tip,
.wpcf7-response-output {
  @apply mt-5px font-fontbase font-medium;
}

.footerfrm .mc4wp-response .mc4wp-error p {
  @apply text-darkred;
}

input.wpcf7-form-control.wpcf7-not-valid,
textarea.wpcf7-form-control.wpcf7-not-valid {
  @apply text-darkred border-darkred;
}

form.wpcf7-form.formfield.init label {
  @apply text-black;
}

form.wpcf7-form.formfield.invalid label,
form.wpcf7-form.invalid label {
  color: #ec4e4e #{!important};
}
form.wpcf7-form.invalid .wpcf7-list-item-label.ctct-label label {
  @apply text-black #{!important};
}

.wpcf7-form label.has-value,
.mc4wp-form label.has-value {
  color: #07122b #{!important};

  @apply -translate-y-20px text-10 leading-14 md:text-12 md:leading-16;
}

.footerfrm .mc4wp-form-fields .input-wrapper {
  @apply mb-0;
}

.mc4wp-form-error .mc4wp-form-fields input,
.mc4wp-form-error .mc4wp-form-fields label {
  @apply text-darkred border-darkred;
}

/* MailChimp Mulit step Form design */

.footerfrmhide .title,
.footerfrmhide .content,
.footerfrmhide #step-input {
  @apply hidden;
}

.mc-field-group {
  @apply mb-20px;
}

.mc-field-group ul li:first-child {
  @apply mb-20px;
}

.check-box {
  @apply hidden w-0 h-0 cursor-pointer;
}

.checklable {
  @apply font-fontbase font-medium text-14 leading-18 text-black pl-35 m-0 relative cursor-pointer;
}

.checklable::before {
  content: '';

  @apply absolute top-0 left-0 w-16 h-16 border-1.5 border-extlightgray transition-all duration-300 ease-in-out;
}

.mc-field-group ul li input:checked ~ .checklable::before {
  @apply bg-black border-black;
}

.mc-field-group ul li input:checked ~ .checklable::after {
  font-family: 'Font Awesome 5 pro';

  content: '\f00c';

  @apply absolute text-white text-14 left-1 top-0;
}

#step_1.field-groupform .formcontent {
  @apply pl-0 xsscreen:text-12 text-gray xsscreen:leading-16;
}
#step_2.field-groupform .formcontent {
  @apply pl-0 md:pl-40 xsscreen:text-12 text-gray xsscreen:leading-16;
}

/* Table design */

/* .bannercontents p {

  @apply font-fontbase font-medium text-16 leading-20 md:text-20 md:leading-20 mdscreen:text-16  xsscreen:inline-flex smscreen:inline-flex smscreen:align-middle md:block ;

} */

/* .bannercontents p span {

  @apply font-fontbase font-medium text-30 leading-36 md:text-40 md:leading-46 mdscreen:text-30 mdscreen:leading-30 xlscreen:text-30 xlscreen:leading-30 desktop:text-30 desktop:leading-30 xssmall:justify-start xssmall1:w-90 xsscreen2:w-50% xsscreen:flex md:block smscreen1:pr-10 smscreen1:mb-0 mb-10;

} */

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  @apply cursor-pointer bg-no-repeat;
}

.tabelmain-section table.dataTable thead th.sorting {
  background-image: url(../assets/images/sort_both.png);

  background-position: 100% center;

  background-size: 16px;
}

.tabelmain-section table.dataTable thead th.sorting_asc {
  background-image: url(../assets/images/sort_asc1.png) #{!important};

  background-size: 16px;

  position: relative;

  background-position: 100% center;
}

.tabelmain-section table.dataTable thead th.sorting_desc {
  background-image: url(../assets/images/sort_desc1.png) #{!important};

  background-size: 16px;

  position: relative;

  background-position: 100% center;
}

.tabelmain-section table.dataTable thead .sorting_desc,
.tabelmain-section table.dataTable thead .sorting.sorting_asc {
  filter: invert(61%) sepia(61%) saturate(6783%) hue-rotate(202deg)
    brightness(98%) contrast(105%);

  filter: invert(61%) sepia(61%) saturate(6783%) hue-rotate(202deg)
    brightness(98%) contrast(105%);
}

.tabelmain-section .sorting_desc .tableheading {
  color: #287eff;
}

.table-main table.dataTable thead .sorting_desc {
  filter: invert(61%) sepia(61%) saturate(6783%) hue-rotate(202deg)
    brightness(98%) contrast(105%);

  @apply relative;
}

.table-main table.dataTable thead .sorting_asc .tableheadicon,
.table-main table.dataTable thead .sorting_desc .tableheadicon {
  @apply relative block max-w-full;
}

.dataTables_wrapper .dataTables_filter input {
  @apply p-5 bg-transparent ml-3;

  border: 1px solid #cdd5e3;

  border-radius: 3px;
}

table.dataTable.display tbody tr.odd > .sorting_1 {
  @apply bg-transparent;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  @apply bg-lightbgcolor #{!important};
}

table.dataTable.hover tbody tr:hover.selected,
table.dataTable.display tbody tr:hover.selected {
  @apply bg-lightbgcolor;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #f5faff #{!important};
}

table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3,
table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3 {
  @apply bg-lightbgcolor;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-top: 1px solid #cdd5e3;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: transparent;
}

table.dataTable.stripe tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected {
  background-color: transparent;
}

table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3,
table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3 {
  background-color: transparent #{!important};
}
table.dataTable.display tbody tr.even.selected > .sorting_1,
table.dataTable.display tbody tr.even.selected > .sorting_2,
table.dataTable.display tbody tr.even.selected > .sorting_3,
table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.display tbody tr.even > .sorting_2,
table.dataTable.display tbody tr.even > .sorting_3,
table.dataTable.display tbody tr.odd,
table.dataTable.display tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected > .sorting_1,
table.dataTable.display tbody tr.odd.selected > .sorting_2,
table.dataTable.display tbody tr.odd.selected > .sorting_3,
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.display tbody tr.odd > .sorting_2,
table.dataTable.display tbody tr.odd > .sorting_3,
table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3,
table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_3,
table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3,
table.dataTable.stripe tbody tr.odd,
table.dataTable.stripe tbody tr.odd.selected {
  background-color: transparent #{!important};
}
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: transparent #{!important};
}

table.dataTable.display tbody tr.odd > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: transparent;
}

table.dataTable.display tbody tr.odd > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: transparent;
}

table.dataTable.display tbody tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: transparent;
}

table.dataTable.display tbody tr.odd.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: transparent;
}

table.dataTable.display tbody tr.odd.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: transparent;
}

table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: transparent;
}

table.dataTable.display tbody tr.even > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: transparent;
}

table.dataTable.display tbody tr.even > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: transparent;
}

table.dataTable.display tbody tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: transparent;
}

table.dataTable.display tbody tr.even.selected > .sorting_2,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: transparent;
}

table.dataTable.display tbody tr.even.selected > .sorting_3,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: transparent;
}

table.dataTable.display tbody tr:hover > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: transparent;
}

table.dataTable.display tbody tr:hover > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  background-color: transparent;
}

table.dataTable.display tbody tr:hover > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  background-color: transparent;
}

table.dataTable.display tbody tr:hover.selected > .sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  background-color: transparent;
}

table.dataTable.display tbody tr:hover.selected > .sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  background-color: transparent;
}

table.dataTable.display tbody tr:hover.selected > .sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  background-color: transparent;
}

table.dataTable tbody td {
  padding: 8px 10px;
}

.sorting_desc .tableheading {
  color: #287eff;
}

.tabelmain-section .dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #cdd5e3;
}

.dataTables_length {
  display: none;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  @apply bg-lightbgcolor #{!important};
}
table.dataTable.hover tbody tr:hover.selected,
table.dataTable.display tbody tr:hover.selected {
  @apply bg-lightbgcolor #{!important};
}
table.dataTable.hover tbody tr .odd:hover,
table.dataTable.display tbody tr .even:hover {
  background-color: #f5faff #{!important};
}

.footerfrm .mc4wp-form-fields {
  @apply mb-10;
}

.footerfrm .mc4wp-form-fields .formfield {
  @apply p-10 border border-extlightgray;
}

.footerfrm .mc4wp-form-fields .formfield:focus {
  @apply bg-white;
}

.footerfrm .mc4wp-form-fields .formfield::placeholder {
  @apply text-black;
}

.footerfrm .mc4wp-response .mc4wp-error p {
  @apply font-medium;
}

.footerfrm .mc4wp-form-fields .input-wrapper {
  @apply mb-0;
}

.brandslidebox .swiper-wrapper {
  transition-timing-function: linear #{!important};

  transition-timing-function: linear #{!important};
}

/* 11JUL22 */
#step-input.mc-field-group.error .formlable,
#step-input.mc-field-group.error .formlable.has-value,
.mc-field-group.error .formlable,
.mc-field-group.error .formlable.has-value,
.mc-field-group.error .formfield,
.mc-field-group.error .submitbtn,
.mc-field-group.error .wpcf7-email {
  @apply text-darkred #{!important};
}
#step-input.mc-field-group.error .formlable,
#step-input.mc-field-group.error .formlable.has-value,
.mc-field-group.error .formlable,
.mc-field-group.error .formlable.has-value,
.mc-field-group.error .formfield,
.mc-field-group.error .submitbtn,
.mc-field-group.error .wpcf7-email {
  @apply border-darkred;
}
.wpcf7 form.sent .wpcf7-response-output {
  @apply border-0 p-0 m-0 text-lightblue;
}
.wpcf7-form.invalid .wpcf7-not-valid-tip {
  @apply text-darkred font-fontbase font-medium;
}
.wpcf7-form.invalid {
  @apply top-[26px];
}
.wpcf7 form.invalid .wpcf7-response-output {
  @apply border-darkred m-0 font-fontbase text-darkred #{!important};
}
.footerfrm form.wpcf7-form.invalid .wpcf7-list-item-label.ctct-label {
  @apply text-black font-medium font-fontbase #{!important};
}
.wpcf7-form.invalid .formfield {
  @apply border-darkred;
}
input[type='checkbox'] {
  @apply w-16 h-16 accent-black;
}
.footer-bottom ul li:last-child {
  @apply md:pl-20;
}

table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1,
table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  @apply bg-transparent;
}
.tabelmain-sectiontable .dataTable.display tbody tr.odd > .sorting_1,
.tabelmain-section
  table.dataTable.order-column.stripe
  tbody
  tr.odd
  > .sorting_1 {
  background: #fff #{!important};
}

.tableiamges {
  @apply mix-blend-darken;
}

.newsgrid-gallery.loading {
  min-height: 500px;
  position: relative;
}
.newsgrid-gallery.loading::before {
  content: '';
  @apply absolute top-100 left-50% bg-center w-[150px] h-[150px] -translate-x-50%;
  background-image: url(../assets/images/loder.gif);
  background-repeat: no-repeat;
}
.brandslidebox .swiper-wrapper {
  transition-timing-function: linear #{!important};
  transition-timing-function: linear #{!important};
}

// cookies bar

#cn-close-notice {
  display: none;
}
.cookie-notice-container {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1584px;
  margin: 0 auto;
}
.cn-text-container {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 0px #{!important};
  padding: 0px;
  color: #fff;
  max-width: 490px;
  text-align: left;
}
.cn-text-container {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.cn-text-container a {
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.5s ease-in-out;
}
.cn-text-container a:hover {
  color: rgba(255, 255, 255, 1);
}
#cn-notice-buttons {
  max-width: 165px;
  display: block;
  width: 100%;
}
#cookie-notice .cn-button {
  width: 100%;
}
#cookie-notice.cookie-notice-hidden .cookie-notice-container {
  display: none #{!important};
}
#cookie-notice .cookie-notice-container {
  display: flex #{!important};
  align-items: center;
}
@media (max-width: 767px) {
  .cn-text-container {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 480px) {
  .cn-text-container {
    font-size: 12px;
    line-height: 16px;
  }
}

// .table-main table.dataTable.display tbody tr.even > .sorting_1,
// table.dataTable.order-column.stripe tbody tr.even > .sorting_1,
// .table-main table.dataTable.stripe tbody tr.odd,
// table.dataTable.display tbody tr.odd {
//   @apply bg-transparent #{!important};
// }
.table-main table.dataTable.hover tbody tr:hover,
.table-main table.dataTable.display tbody tr:hover {
  background-color: #f5faff !important;
}
.footerfrm .wpcf7-form.invalid .submitbtn {
  @apply top-[20px] text-darkred;
}
.footerfrm .wpcf7-form.invalid .submitbtn {
  @apply text-darkred #{!important};
}
.wpcf7 form.invalid .wpcf7-response-output {
  @apply border-darkred m-0 font-fontbase text-darkred;
}
.wpcf7 form.invalid .wpcf7-response-output {
  @apply border-darkred text-darkred #{!important};
}

.page-cookie-policy .news-gallerydetails .popup-content h2,
.page-cookie-policy .news-gallerydetails .popup-content h3,
.page-cookie-policy .news-gallerydetails .popup-content h4,
.page-privacy-policy .news-gallerydetails .popup-content h2,
.page-privacy-policy .news-gallerydetails .popup-content h3,
.page-privacy-policy .news-gallerydetails .popup-content h4 {
  @apply mb-[30px];
}

.page-cookie-policy .news-gallerydetails .popup-content ol,
.page-cookie-policy .news-gallerydetails .popup-content ul,
.page-privacy-policy .news-gallerydetails .popup-content ol,
.page-privacy-policy .news-gallerydetails .popup-content ul {
  @apply mb-40;
}
.page-cookie-policy .news-gallerydetails .popup-content ol li,
.page-cookie-policy .news-gallerydetails .popup-content ul li,
.page-privacy-policy .news-gallerydetails .popup-content ol li,
.page-privacy-policy .news-gallerydetails .popup-content ul li {
  @apply text-16 leading-20 md:text-20 md:leading-24 mb-[20px];
  line-height: 30px #{!important};
}
.page-cookie-policy .news-gallerydetails .popup-content ol li p,
.page-cookie-policy .news-gallerydetails .popup-content ul li p,
.page-privacy-policy .news-gallerydetails .popup-content ol li p,
.page-privacy-policy .news-gallerydetails .popup-content ul li p {
  @apply mb-16;
}

@media (max-width: 767px) {
  .page-cookie-policy .news-gallerydetails .popup-content h2,
  .page-cookie-policy .news-gallerydetails .popup-content h3,
  .page-cookie-policy .news-gallerydetails .popup-content h4,
  .page-privacy-policy .news-gallerydetails .popup-content h2,
  .page-privacy-policy .news-gallerydetails .popup-content h3,
  .page-privacy-policy .news-gallerydetails .popup-content h4 {
    @apply mb-[15px];
  }
  .page-cookie-policy .news-gallerydetails .popup-content ol,
  .page-cookie-policy .news-gallerydetails .popup-content ul,
  .page-privacy-policy .news-gallerydetails .popup-content ol,
  .page-privacy-policy .news-gallerydetails .popup-content ul {
    @apply mb-20;
  }
}

/* 14JUL22 */
@media (max-width: 810px) {
  .headervideo {
    @apply h-[calc(100vh_-_175px)];
  }
}
@screen xssmall {
  h1.bannerintitle {
    @apply text-30 leading-30;
  }
  .content.head_video-content p {
    @apply text-13 leading-[15px];
  }
  .video_subtitle {
    @apply mt-40;
  }
  .headervideo {
    /*  @apply h-[calc(100vh_-_195px)]; */
    @apply h-[calc(100vh_-_230px)];
  }
}
